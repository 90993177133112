import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Main from "../components/main"
import { Link } from "gatsby"
import { Box, Grid, Heading, Styled } from "theme-ui"

/*
 * Esta es la forma extra abreviada de declarar un componente.
 * No lleva ni nombre de componente.
 * Fijate que no usa {} por que no hay variables en el componente.
 * Fijate que no usa return.
 * Mira comentarios en archivo layout
 *
 */
export default () => (
  <Layout>
    <Header>
      <Heading as={`h1`}>No encontrado</Heading>
    </Header>
    <Main>
      <Grid
        as={`div`}
        gap={0}
        columns={`1fr`}
        sx={{
          backgroundColor: `black`,
          height: `100vh`,
        }}
      >
        <Box
          as={`div`}
          sx={{
            px: 4,
            "p": {
              color: `white`,
              fontSize: [5, 6],
            },
          }}
        >
          <Styled.p>Perdona esta url no existe.<br/>Prueba volver al <Styled.a as={Link} to="/" aria-label="inicio">inicio</Styled.a></Styled.p>
        </Box>
      </Grid>
    </Main>
  </Layout>
)
